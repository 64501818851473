<template>
  <div class="apply__container">
    <el-form
      ref="applicantForm"
      class="custom-form"
      :model="applicantForm"
      :rules="applicantRules"
      inline
    >
      <!-- 지원정보 -->
      <div>
        <h3 class="font-bold text-xl mt-5 mb-5">
          <span class="highlight required">
            <span>지원정보</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="jobId">
            <!-- <el-select clearable v-model="applicantForm.jobId" :placeholder="$t('recruit.form.job')" filterable style="width: 400px" value="">
              <el-option v-for="item in appliableList" :key="item.id" :label="item.alias" :value="item.id" />
            </el-select> -->
            <el-cascader
              v-model="selectedValue"
              :options="cascaderList"
              show-all-levels
              filterable
              :placeholder="$t('recruit.form.job')"
              style="width: 400px"
              value=""
              separator=" > "
              @change="(value) => applicantForm.jobId = value[1]"
            />
          </el-form-item>
        </div>
      </div>
      <!-- 기본 인적사항 -->
      <div>
        <h3 class="font-bold text-xl mt-6 mb-5">
          <span class="highlight required">
            <span>기본 인적사항</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="name">
            <el-input
              v-model="applicantForm.name"
              :placeholder="$t('recruit.form.name')"
              name="namefield"
              class="filter-item"
              style="width: 100px"
            />
          </el-form-item>
          <el-form-item prop="englishName">
            <el-input
              v-model="applicantForm.englishName"
              :placeholder="$t('recruit.form.englishName')"
              name="namefield"
              class="filter-item"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item prop="birthday">
            <el-input
              v-model.trim="applicantForm.birthday"
              :maxlength="10"
              :placeholder="$t('recruit.form.birthday')"
              name="namefield"
              class="filter-item"
              style="width: 180px"
              @keydown.native="autoCompleteBirthday"
            />
          </el-form-item>
          <el-form-item prop="sex">
            <el-select
              v-model="applicantForm.sex"
              clearable
              value=""
              :placeholder="$t('recruit.form.sex')"
              class="filter-item"
              style="width: 100px"
            >
              <el-option
                value="남자"
                :label="$t('recruit.form.sex_male')"
              />
              <el-option
                value="여자"
                :label="$t('recruit.form.sex_female')"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="applicantForm.sex === '남자'"
            prop="armyStatus"
          >
            <el-select
              v-model="applicantForm.armyStatus"
              clearable
              value=""
              :placeholder="$t('recruit.form.armyStatus')"
              class="filter-item"
              style="width: 200px"
            >
              <el-option
                v-for="(status, stIdx) in armyStatusList"
                :key="`armyStatus${stIdx}`"
                :value="stIdx"
                :label="status"
              />
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="email">
            <el-input
              v-model="applicantForm.email"
              :placeholder="$t('recruit.form.email')"
              type="email"
              class="filter-item"
              style="width: 290px"
            />
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              v-model="applicantForm.phone"
              :maxlength="11"
              :placeholder="$t('recruit.form.phone')"
              type="tel"
              class="filter-item"
              style="width: 290px"
            />
          </el-form-item>
          <!-- <el-form-item prop="incomingRouteId">
            <el-select
              v-model="applicantForm.incomingRouteId"
              clearable
              :placeholder="$t('recruit.form.applyRoute')"
              style="width: 280px"
            >
              <el-option
                v-for="item in routeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item> -->
        </div>
      </div>
    </el-form>
    <!-- 추가 인적사항 -->
    <div
      v-if="!isFreelancer"
      class="mt-8 flex"
    >
      <div class="w-1/4">
        <h3 class="font-bold text-xl mb-5 flex items-center">
          <span class="highlight required">
            <span>보훈대상 여부</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-radio
            v-model="patriotYN"
            label="patriotY"
          >
            대상
          </el-radio>
          <el-radio
            v-model="patriotYN"
            label="patriotN"
          >
            비대상
          </el-radio>
        </div>
      </div>
      <div class="w-3/4">
        <h3 class="font-bold text-xl mb-5 flex items-center">
          <span class="highlight required">
            <span>장애사항</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-select
            v-model="handicap"
            value=""
            style="width: 200px"
          >
            <el-option
              v-for="(item, idx) in handicapList"
              :key="`handicap${idx}`"
              :value="idx"
              :label="item"
            />
          </el-select>
        </div>
      </div>
    </div>
    <!-- 학력 사항 -->
    <div
      v-if="!isFreelancer"
      class="education--form"
    >
      <h3 class="font-bold text-xl mt-12 mb-5">
        <span class="highlight required">
          <span>학력사항</span>
        </span>
        <i class="highlight__star required">*</i>
      </h3>
      <p class="text-sm mb-1 ml-3">
        ✔ 학력사항은 고등학교에서부터 현 최종학력 순으로 순차적으로
        기재해주시기 바랍니다.
      </p>
      <p class="text-sm mb-6 ml-3">
        ✔ 복수전공 및 이중전공을 이수하신 분들은 부전공란에 입력해주세요.
      </p>
      <el-card
        class="mt-2"
      >
        <div
          v-for="(edu, idx) in educations"
          :key="`edu${idx}`"
          class="edu__list"
        >
          <el-select
            v-model="edu.type"
            clearable
            value=""
            class="mr-1 mb-1"
            placeholder="* 구분"
            style="width: 125px"
          >
            <el-option
              v-for="(eduType, eduIdx) in eduTypeList"
              :key="`projectType${eduIdx}`"
              :value="eduIdx"
              :label="eduType"
            />
          </el-select>
          <el-input
            v-model="edu.name"
            class="mr-1 mb-1"
            placeholder="* 학교명"
            style="width: 125px"
          />
          <el-input
            v-if="edu.type"
            v-model="edu.department"
            class="mr-1 mb-1"
            placeholder="전공"
            style="width: 120px"
          />
          <el-input
            v-if="edu.type===1"
            v-model="edu.subDepartment"
            class="mr-1 mb-1"
            placeholder="부전공"
            style="width: 120px"
          />
          <el-date-picker
            v-model="edu.startDate"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            default-value="2010-10-01"
            placeholder="* 입학년월"
            style="width: 125px"
          />
          <el-date-picker
            v-model="edu.endDate"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            default-value="2010-10-01"
            placeholder="졸업년월"
            style="width: 125px"
          />
          <div class="float-right mt-1">
            <el-button
              v-if="idx === 0"
              class="ml-3"
              size="mini"
              type="success"
              circle
              icon="el-icon-plus"
              @click="addEdu"
            />
            <el-button
              v-else
              class="ml-3"
              size="mini"
              type="danger"
              circle
              icon="el-icon-minus"
              @click="removeEdu(idx)"
            />
          </div>
        </div>
      </el-card>
    </div>
    <!-- 경력 사항 -->
    <div class="relative">
      <h3 class="font-bold text-xl pt-8 pb-5 flex items-center">
        <template v-if="!isFresh">
          <span class="highlight required">
            <span>경력사항</span>
          </span>
          <i class="highlight__star required">*</i>
        </template>
        <template v-else>
          경력사항
        </template>
        <el-button
          style="margin-left: 10px"
          size="mini"
          type="success"
          circle
          icon="el-icon-plus"
          @click="addCareer"
        />
      </h3>
      <template v-if="careers.length">
        <div class="text-sm text-gray-600">
          <p class="mb-1 ml-3">
            ✔ 쿡앱스는 지원자에 대해 궁금합니다! 지원하신 직무와 관련된 세부
            경력사항을 아래에 상세히 작성해 주세요.
          </p>
          <p class="mb-2 ml-3">
            ✔ 신입 / 인턴 포지션 지원 시 관련 경험이 없다면 경력사항은
            생략해도 괜찮아요.
          </p>
          <el-checkbox
            v-model="isFresh"
            class="ml-3 mb-4"
          >
            경력이 없는 신입 / 인턴 지원자입니다.
          </el-checkbox>
        </div>
        <el-card
          v-for="(career, idx) in careers"
          :key="`career${idx}`"
          class="mt-2"
        >
          <el-input
            v-model="career.company"
            class="mr-1 mb-1"
            placeholder="* 회사명"
            style="width: 180px"
          />
          <el-input
            v-model="career.department"
            class="mr-1 mb-1"
            placeholder="부서/스튜디오명"
            style="width: 180px"
          />
          <el-input
            v-model="career.position"
            class="mr-1 mb-1"
            placeholder="직위"
            style="width: 130px"
          />
          <el-input
            v-model="career.job"
            class="mr-5 mb-1"
            placeholder="* 직무"
            style="width: 150px"
          />
          <el-checkbox
            v-model="career.isNowChecked"
            class="mr-1 mb-1"
          >
            재직중
          </el-checkbox>
          <div class="float-right mt-1">
            <el-button
              class="mr-2.5"
              size="mini"
              type="danger"
              circle
              icon="el-icon-minus"
              @click="removeCareer(idx)"
            />
          </div>
          <el-date-picker
            v-model="career.startDate"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            placeholder="* 입사일"
            style="width: 180px"
          />
          <el-date-picker
            v-show="!career.isNowChecked"
            v-model="career.endDate"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            placeholder="* 퇴사일"
            style="width: 180px"
          />
          <el-input
            v-show="!career.isNowChecked"
            v-model="career.reason"
            class="mr-1"
            placeholder="퇴사사유"
            style="width: 285px"
            maxlength="50"
            show-word-limit
          />
          <el-input
            v-model="career.salary"
            class="mr-1 mb-1"
            placeholder="연봉"
            style="width: 130px"
          />
          <span class="text-sm">만원</span>
          <el-input
            v-model="career.desc"
            type="textarea"
            placeholder="경력에 대한 세부적인 내용을 작성해주세요."
            rows="6"
            maxlength="1000"
            show-word-limit
          />
        </el-card>
        <div class="text-left pt-5">
          <el-upload
            class="inline-block custom__file__upload"
            action=""
            multiple
            :auto-upload="false"
            :on-remove="handleCareerFileRemoveChange"
            :on-change="handleCareerChange"
            :file-list="careerUpload"
          >
            <span
              class="text-sm text-gray-600 font-normal relative hover:underline"
            >
              추가 경력 기술서 제출을 희망하신다면?
            </span>
            <span
              class="text-sm font-bold fontColorPrimary"
            >
              파일업로드
            </span>
          </el-upload>
        </div>
      </template>
    </div>
    <div
      class="mt-12"
      style="height: 8px; background-color: #E1E1E1; margin-left: -3rem; width: calc( 100% + 6rem)"
    />
    <!-- 보유기술  -->
    <div>
      <h3 class="font-bold text-xl mt-12 mb-5">
        보유 기술
        <el-button
          v-if="!isSkillVisible"
          class="ml-2.5 relative"
          size="mini"
          type="success"
          circle
          icon="el-icon-plus"
          style="bottom: 3px;"
          @click="isSkillVisible = true"
        />
        <el-button
          v-else
          class="ml-2.5 relative"
          size="mini"
          type="danger"
          circle
          icon="el-icon-minus"
          style="bottom: 3px;"
          @click="skill = ''; isSkillVisible = false"
        />
      </h3>
      <div
        v-show="isSkillVisible"
        class="text-sm"
      >
        <el-input
          v-model="skill"
          type="textarea"
          rows="5"
          maxlength="1500"
          show-word-limit
          placeholder="작성 시 보유 기술과 숙련도를 함께 작성해 주세요.
예 ) 영어, 외국인과 의사소통 가능 (Native)
예 ) Unity3D 엔진 개발 능력 상
예 ) 유저 스토리 보드 및 플로우 설계 능력 상 "
        />
      </div>
    </div>
    <!-- 자격 사항 -->
    <div v-if="!isFreelancer">
      <h3 class="font-bold text-xl mt-12 mb-5">
        <span>
          <span>자격증</span>
        </span>
        <el-button
          v-if="!isCertiVisible"
          class="ml-2.5 relative"
          size="mini"
          type="success"
          circle
          icon="el-icon-plus"
          style="bottom: 3px;"
          @click="isCertiVisible = true"
        />
        <el-button
          v-else
          class="ml-2.5 relative"
          size="mini"
          type="danger"
          circle
          icon="el-icon-minus"
          style="bottom: 3px;"
          @click="certifications = [...certiMock]; isCertiVisible = false"
        />
      </h3>
      <div v-show="isCertiVisible">
        <el-card
          v-for="(certi, idx) in certifications"
          :key="`certi${idx}`"
          class="mt-2"
        >
          <el-select
            v-model="certi.type"
            clearable
            value=""
            class="mr-1 mb-1"
            placeholder="* 구분"
            style="width: 115px"
          >
            <el-option
              v-for="(certiType, certiIdx) in certiTypeList"
              :key="`certiType${certiIdx}`"
              :value="certiIdx"
              :label="certiType"
            />
          </el-select>
          <el-input
            v-if="certi.type"
            v-model="certi.lang"
            class="mr-1 mb-1"
            placeholder="언어"
            style="width: 95px"
          />
          <el-input
            v-model="certi.exam"
            class="mr-1 mb-1"
            placeholder="시험/자격 종류"
            style="width: 120px"
          />
          <el-input
            v-model="certi.grade"
            class="mr-1 mb-1"
            placeholder="등급"
            style="width: 90px"
          />
          <el-input
            v-if="certi.type"
            v-model="certi.score"
            class="mr-1 mb-1"
            placeholder="점수"
            style="width: 90px"
          />
          <el-date-picker
            v-model="certi.date"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            placeholder="취득/응시일"
            style="width: 130px"
          />
          <el-input
            v-model="certi.organization"
            class="mr-1 mb-1"
            placeholder="발급기관"
            style="width: 110px"
          />
          <div class="float-right mt-1">
            <el-button
              v-if="idx === 0"
              class="ml-3"
              size="mini"
              type="success"
              circle
              icon="el-icon-plus"
              @click="addCerti"
            />
            <el-button
              v-else
              class="ml-3"
              size="mini"
              type="danger"
              circle
              icon="el-icon-minus"
              @click="removeCerti(idx)"
            />
          </div>
        </el-card>
      </div>
    </div>
    <!-- 교육 이수  -->
    <div>
      <h3 class="font-bold text-xl mt-12 mb-5">
        교육 이수
        <el-button
          v-if="!isAcademyVisible"
          class="ml-2.5 relative"
          size="mini"
          type="success"
          circle
          icon="el-icon-plus"
          style="bottom: 3px;"
          @click="isAcademyVisible = true"
        />
        <el-button
          v-else
          class="ml-2.5 relative"
          size="mini"
          type="danger"
          circle
          icon="el-icon-minus"
          style="bottom: 3px;"
          @click="isAcademyVisible = false"
        />
      </h3>
      <div
        v-show="isAcademyVisible"
        class="text-sm"
      >
        <el-input
          v-model="academy"
          type="textarea"
          rows="5"
          maxlength="1500"
          show-word-limit
          placeholder="지원한 직무와 관련된 교육 이수 내용을 작성해 주세요."
        />
      </div>
    </div>
    <!-- 수상 및 기타  -->
    <div>
      <h3 class="font-bold text-xl mt-12 mb-5">
        수상 및 기타
        <el-button
          v-if="!isAwardVisible"
          class="ml-2.5 relative"
          size="mini"
          type="success"
          circle
          icon="el-icon-plus"
          style="bottom: 3px;"
          @click="isAwardVisible = true"
        />
        <el-button
          v-else
          class="ml-2.5 relative"
          size="mini"
          type="danger"
          circle
          icon="el-icon-minus"
          style="bottom: 3px;"
          @click="isAwardVisible = false"
        />
      </h3>
      <div
        v-show="isAwardVisible"
        class="text-sm"
      >
        <el-input
          v-model="award"
          type="textarea"
          rows="5"
          maxlength="1500"
          show-word-limit
          placeholder="수상 이력, 직무 관련 활동 등이 있다면 관련 내용을 작성해 주세요."
        />
      </div>
    </div>
    <!-- 직무 질문 -->
    <!-- <div
    v-if="!isFreelancer"
    class="mt-12"
    >
    <h3 class="font-bold text-xl mt-10 mb-5 flex items-center">
      <span class="highlight required">
        <span>직무 질문</span>
      </span>
      <i class="highlight__star required">*</i>
    </h3>
    <h3
      v-if="applicantForm.jobId && appliableList.length"
      class="text-bold text-base mt-5 mb-5 ml-1"
      style="text-indent: -21px; margin-left:31px;"
    >
      <b class="text-base">Q. </b> 쿡앱스의 "{{ appliableList.find(j => j.id === applicantForm.jobId).name }}" 부문에 지원 동기는 무엇인가요? (현재 회사에서의 이직 사유도 포함해서 작성해주세요.)
    </h3>
    <el-input
      v-if="applicantForm.jobId && appliableList.length"
      v-model="introduction"
      type="textarea"
      rows="6"
      maxlength="1500"
      show-word-limit
    />
    <h3
      class="text-bold text-base mt-5 mb-5 ml-1"
      style="text-indent: -21px; margin-left:31px;"
    >
      <b class="text-base">Q. </b>지원하신 분야에 있어 강점이 무엇인지 알려주시고, 그 강점이 앞으로 쿡앱스와 본인의 발전에 어떻게 도움이 될 수 있는지 기술해 주세요.
    </h3>
    <el-input
      v-model="goal"
      type="textarea"
      rows="6"
      maxlength="1500"
      show-word-limit
    />
    </div> -->
    <!-- 프로젝트 수행 이력 -->
    <div v-if="!isFreelancer">
      <h3 class="font-bold text-xl pt-10 pb-5 flex items-center relative">
        추가 프로젝트 수행이력
        <el-button
          v-if="!isProjectVisible"
          class="ml-2.5 relative"
          size="mini"
          type="success"
          circle
          icon="el-icon-plus"
          style="bottom: 3px;"
          @click="isProjectVisible = true"
        />
        <el-button
          v-else
          class="ml-2.5 relative"
          size="mini"
          type="danger"
          circle
          icon="el-icon-minus"
          style="bottom: 3px;"
          @click="projects = [...projectMock]; isProjectVisible = false"
        />
      </h3>
      <template v-if="projects && projects.length && isProjectVisible">
        <div class="mb-4 text-sm text-gray-600">
          <p
            class="mb-1 ml-6"
            style="text-indent: -0.75rem"
          >
            ✔ 작성해주신 관련 경력 사항 이외에 추가적으로 진행한 프로젝트
            수행이력이 있다면 아래에 작성해 주세요. (예시: 1인 및 인디게임
            개발, 비영리단체 운영, 창업 등)
          </p>
          <p class="mb-1 ml-3">
            ✔ 포지션과 주요 책임, 기여도에 대해 간략하게 작성해 주세요.
          </p>
        </div>
        <el-card
          v-for="(project, idx) in projects"
          :key="`project${idx}`"
          class="mt-2"
        >
          <el-input
            v-model="project.title"
            class="mr-1 mb-1"
            placeholder="* 프로젝트명"
            style="width: 180px"
          />
          <el-select
            v-model="project.group"
            clearable
            allow-create
            filterable
            default-first-option
            value=""
            class="mr-1 mb-1"
            placeholder="회사 / 단체명"
            style="width: 180px"
          >
            <el-option
              v-for="(company, idx) in companies"
              :key="`company${idx}`"
              :value="company"
              :label="company"
            />
          </el-select>
          <el-date-picker
            v-model="project.startDate"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            placeholder="* 시작날짜"
            style="width: 180px"
          />
          <el-date-picker
            v-model="project.endDate"
            value-format="yyyy-MM"
            type="month"
            class="mr-1 mb-1"
            placeholder="* 종료날짜"
            style="width: 180px"
          />
          <div class="float-right mt-1">
            <el-button
              class="mr-2.5"
              size="mini"
              type="success"
              circle
              icon="el-icon-plus"
              @click="addProject"
            />
          </div>
          <el-input
            v-model="project.desc"
            type="textarea"
            placeholder="프로젝트에 대한 상세 내용을 작성해주세요."
            rows="6"
            maxlength="1000"
            show-word-limit
          />
        </el-card>
      </template>
    </div>

    <!-- S: 첨부 자료 -->
    <div class="mt-12">
      <h3 class="font-bold text-xl mt-10 mb-5 flex items-center">
        첨부자료
      </h3>
      <div class="text-sm mb-7">
        <p class="mb-1 ml-3 font-primary-color">
          <i
            style="font-size: 18px; top: 7px"
            class="highlight__star mr-0.5"
          >*</i>
          포트폴리오는 Word/Excel/PPT 혹은 PDF 형식으로 올려주세요.
        </p>
        <p class="mb-1 ml-3 font-primary-color">
          <i
            style="font-size: 18px; top: 7px"
            class="highlight__star mr-0.5"
          >*</i>
          zip 파일은 부득이한 경우 (샘플 코드, 대용량 원화파일 등)을
          제외하고 압축 해제 후 필요한 파일만 올려주세요.
        </p>
      </div>
      <div
        class="ml-1"
        style="width: 42%"
      >
        <el-upload
          action=""
          drag
          multiple
          :auto-upload="false"
          :file-list="uploadList"
          :on-remove="handleFileRemoveChange"
          :on-change="handleUploadChange"
        >
          <i class="el-icon-upload" />
          <div
            class="el-upload__text"
            v-html="$t('recruit.form.file_desc')"
          />
          <div>{{ $t('recruit.form.file_desc2') }}</div>
        </el-upload>
      </div>
      <div
        v-for="(url, urlIdx) in urlList"
        :key="`url${urlIdx}`"
        class="mb-1 urlList"
      >
        <el-input
          :key="`urlInput${urlIdx}`"
          v-model="urlList[urlIdx]"
          placeholder="http://"
          class="filter-item ml-1 mr-2"
          style="width: 360px"
        />
        <el-button
          v-if="!urlIdx"
          icon="el-icon-plus"
          type="primary"
          size="mini"
          circle
          @click="urlList.push('')"
        />
        <el-button
          v-else
          :key="`urlBtn${urlIdx}`"
          icon="el-icon-minus"
          type="danger"
          size="mini"
          circle
          @click="urlList.splice(urlIdx, 1)"
        />
      </div>
    </div>
    <!-- E: 첨부 자료 -->

    <!-- S: 버튼 -->
    <div class="applyNew__btnWrap overflow-hidden">
      <el-button
        class="float-left"
        icon="el-icon-arrow-left"
        style="width: auto !important; border: none !important"
        @click="$router.push('/recruit/my-apply');"
      >
        목록으로 돌아가기
      </el-button>
      <div class="float-right">
        <el-button
          type="plain"
          class="mr-2"
          @click="openTempSaveModal"
        >
          임시저장
        </el-button>
        <el-button
          type="primary"
          @click="CheckFinally"
        >
          최종 점검
        </el-button>
      </div>
    </div>
    <!-- E: 버튼 -->

    <!-- S: 미리보기 -->
    <el-dialog
      v-loading="dialogLoading"
      class="applyNew__preview"
      :visible.sync="showFinalCheck"
      width="960px"
    >
      <el-row
        class="mb-5 p-5 text-black"
        style="margin-top: -20px"
      >
        <el-col>
          <h4 class="text-2xl font-bold mb-2">
            기본 인적사항
          </h4>
          <div class="mt-6 ml-1.5 mb-2 info__list">
            <p
              v-if="applicantForm.name"
              class="text-lg inline-block"
              style="margin-right: 15px"
            >
              {{ applicantForm.name }}
            </p>
            <p
              v-if="applicantForm.englishName"
              class="text-lg inline-block"
              style="margin-right: 15px"
            >
              {{ applicantForm.englishName }}
            </p>
            <p
              v-if="applicantForm.birthday"
              class="text-lg inline-block"
              style="margin-right: 15px"
            >
              {{ applicantForm.birthday }}
            </p>
            <p
              v-if="applicantForm.sex"
              class="text-lg inline-block"
              style="margin-right: 15px"
            >
              {{ applicantForm.sex }}
            </p>
            <p
              v-if="applicantForm.sex==='남자'"
              class="text-lg inline-block"
            >
              {{ armyStatusList[applicantForm.armyStatus] }}
            </p>
          </div>
          <div class="ml-1.5 info__list">
            <p
              class="text-lg inline-block"
              style="margin-right: 15px"
            >
              {{ applicantForm.email }}
            </p>
            <p class="text-lg inline-block">
              {{ applicantForm.phone }}
            </p>
          </div>
          <el-divider
            class="mt-10 mb-10 bg-gray-400"
            style="height: 0.1rem"
          />
        </el-col>
        <el-col>
          <h4 class="text-2xl font-bold mb-2">
            추가 인적사항
          </h4>
          <div class="mt-6 ml-1.5 mb-2 text-sm info__list">
            <p
              class="text-lg inline-block"
              style="margin-right: 15px"
            >
              <b>보훈대상 여부 : </b> {{ patriot ? "대상" : "비대상" }}
            </p>
            <p class="text-lg inline-block">
              <b>장애사항 : </b> {{ handicapList[handicap] }}
            </p>
          </div>
          <el-divider
            class="mt-10 mb-10 bg-gray-400"
            style="height: 0.1rem"
          />
        </el-col>
        <el-col>
          <h4 class="text-2xl font-bold mb-2">
            학력사항
          </h4>
          <el-table :data="educations">
            <el-table-column label="구분">
              <template slot-scope="scope">
                {{ eduTypeList[scope.row.type] }}
              </template>
            </el-table-column>
            <el-table-column
              label="학교"
              prop="name"
            />
            <el-table-column label="학과">
              <template slot-scope="scope">
                {{ scope.row.department || '-' }} /
                {{ scope.row.subDepartment || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="기간">
              <template slot-scope="scope">
                {{ scope.row.startDate }} ~ {{ scope.row.endDate }}
              </template>
            </el-table-column>
          </el-table>
          <el-divider
            class="mt-10 mb-10 bg-gray-400"
            style="height: 0.1rem"
          />
        </el-col>
        <el-col>
          <h4 class="text-2xl font-bold mb-2">
            자격증
          </h4>
          <el-table :data="certifications">
            <el-table-column label="구분">
              <template slot-scope="scope">
                {{ certiTypeList[scope.row.type] }}
                <span
                  v-if="scope.row.type"
                >/ {{ scope.row.lang || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="시험/자격 종류">
              <template slot-scope="scope">
                {{ scope.row.exam || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="등급">
              <template slot-scope="scope">
                {{ scope.row.grade || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="점수">
              <template slot-scope="scope">
                {{ scope.row.score || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="취득/응시일">
              <template slot-scope="scope">
                {{ scope.row.date || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="발급기관">
              <template slot-scope="scope">
                {{ scope.row.organization || '-' }}
              </template>
            </el-table-column>
          </el-table>
          <el-divider
            class="mt-10 mb-10 bg-gray-400"
            style="height: 0.1rem"
          />
        </el-col>
        <el-col>
          <h4 class="text-2xl font-bold mb-2">
            경력사항
          </h4>
          <el-table :data="careers">
            <el-table-column
              label="회사"
              prop="company"
            />
            <el-table-column label="부서">
              <template slot-scope="scope">
                {{ scope.row.department || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="기간">
              <template slot-scope="scope">
                {{ scope.row.startDate }} ~ {{ scope.row.endDate }}
              </template>
            </el-table-column>
            <el-table-column
              label="직위"
              prop="position"
            />
            <el-table-column
              label="직무"
              prop="job"
            />
            <el-table-column label="연봉">
              <template slot-scope="scope">
                {{ scope.row.salary ? `${scope.row.salary}만원`: '-' }}
              </template>
            </el-table-column>
            <el-table-column label="퇴사사유">
              <template slot-scope="scope">
                {{ scope.row.isNowChecked ? "재직중" : scope.row.reason }}
              </template>
            </el-table-column>
            <el-table-column
              label="상세내용"
              type="expand"
              width="100"
            >
              <template slot-scope="scope">
                <p v-html="scope.row.desc.replace(/\n/g, '<br/>')" />
              </template>
            </el-table-column>
          </el-table>
          <div class="pt-4">
            <p
              v-for="upload in careerUpload"
              :key="upload.uid"
              class="mt-2 mb-2"
            >
              {{ upload.name }}
            </p>
          </div>
          <el-divider
            class="mt-10 mb-10 bg-gray-400"
            style="height: 0.1rem"
          />
        </el-col>
        <el-col>
          <h4 class="text-2xl font-bold mb-2">
            프로젝트 수행이력
          </h4>
          <el-table :data="projects">
            <el-table-column
              label="프로젝트명"
              prop="title"
            />
            <el-table-column
              label="조직"
              prop="group"
            />
            <el-table-column label="기간">
              <template slot-scope="scope">
                {{ scope.row.startDate }} ~ {{ scope.row.endDate }}
              </template>
            </el-table-column>
            <el-table-column
              label="상세 내용"
              type="expand"
              width="100"
            >
              <template slot-scope="scope">
                <p v-html="scope.row.desc.replace(/\n/g, '<br/>')" />
              </template>
            </el-table-column>
          </el-table>
          <el-divider
            class="mt-10 mb-10 bg-gray-400"
            style="height: 0.1rem"
          />
        </el-col>
        <el-col>
          <h4 class="text-lg font-bold mb-2">
            보유 기술
          </h4>
          <div
            v-if="skill"
            class="p-2 mb-3"
            v-html="skill.replace(/\n/g, '<br/>')"
          />
          <h4 class="text-lg font-bold mb-2">
            교육 이수
          </h4>
          <div
            v-if="academy"
            class="p-2 mb-3"
            v-html="academy.replace(/\n/g, '<br/>')"
          />
          <h4 class="text-lg font-bold mb-2">
            수상 및 기타
          </h4>
          <div
            v-if="award"
            class="p-2 mb-3"
            v-html="award.replace(/\n/g, '<br/>')"
          />
        </el-col>
        <el-col>
          <el-row>
            <h4 class="text-lg font-bold mb-2">
              첨부자료
            </h4>
            <p
              v-for="upload in uploadList"
              :key="upload.uid"
              class="mb-2"
            >
              {{ upload.name }}
            </p>
            <p
              v-for="(url, urlIdx) in urlList"
              :key="`showUrl${urlIdx}`"
            >
              <a
                class="cursor-pointer text-blue-500 mb-2"
                :href="url"
                target="_blank"
              >{{ url }}</a>
            </p>
          </el-row>
        </el-col>
      </el-row>
      <div
        slot="footer"
        class="applyNew__btnWrap"
      >
        <el-button @click="showFinalCheck = false">
          돌아가기
        </el-button>
        <el-button
          :disabled="clickedApplyBtn"
          type="primary"
          @click="apply"
        >
          최종 접수
        </el-button>
      </div>
    </el-dialog>
    <!-- E: 미리보기 -->

    <!-- S: 임시 저장 모달 -->
    <div
      ref="temporarySaveModal"
      class="temp__save__modal"
      style="display: none"
    >
      <button
        class="tsmd__dim"
        @click="closeTempSaveModal"
      />
      <div
        class="tsmd__inner"
        style="width: 550px; height: 350px"
      >
        <button
          class="tsmd__close__btn el-dialog__close el-icon el-icon-close"
          @click="closeTempSaveModal"
        />
        <div class="tsmd__txt">
          임시저장을 하시겠습니까?
        </div>
        <div class="tsmd__subTxt pt-4">
          임시저장한 지원서를 불러오기 위한 계정정보를 기억해주시기
          바랍니다.<br>
        </div>
        <div class="tsmd__btn__box">
          <button
            class="tsmd__cancel__btn"
            @click="closeTempSaveModal"
          >
            취소
          </button>
          <button
            class="tsmd__save__btn"
            @click="saveTemp(applicationId)"
          >
            임시저장
          </button>
        </div>
      </div>
    </div>
    <!-- E: 임시 저장 모달 -->

    <!-- S: 로딩 모달 -->
    <div
      v-show="applyLoading"
      class="loading__modal"
    >
      <div class="tsmd__dim" />
      <div class="cookie__loading">
        <div class="cookie__loading--img">
          <img
            class="cookie"
            style="width: 400px"
            :src="`${loadingGif}?ts=${timeStamp}`"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- E: 로딩 모달 -->
  </div>
</template>
<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { getJobs, getRoute, getUploadUrl } from '@/api/job';
import { registerApplication, getApplyDetail, saveTemp, reRegister, removeFile } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  name: 'ApplyDefaultForm',
  data() {
    return {
      applyJobId: 0,
      isTemp: false,
      isFresh: false,
      applicationId: 0,
      applyLoading: false,
      loading: false,
      formData: this.applicantForm,
      dialogLoading: false,
      armyStatusList: [
        this.$t('recruit.form.army_done'),
        this.$t('recruit.form.army_skip'),
        this.$t('recruit.form.army_notYet'),
        this.$t('recruit.form.army_none'),
        this.$t('recruit.form.army_proIng'),
      ],
      eduTypeList: [
        this.$t('recruit.form.edu_type.0'),
        this.$t('recruit.form.edu_type.1'),
        this.$t('recruit.form.edu_type.2'),
        this.$t('recruit.form.edu_type.3'),
      ],
      certiTypeList: [
        this.$t('recruit.form.certi_type.0'),
        this.$t('recruit.form.certi_type.1'),
      ],
      projectTypeList: [
        this.$t('recruit.form.project_type.0'),
        this.$t('recruit.form.project_type.1'),
        this.$t('recruit.form.project_type.2'),
        this.$t('recruit.form.project_type.3'),
      ],
      selectedValue: [],
      appliableList: [],
      cascaderList: [],
      routeList: [],
      applicantForm: {
        recruitPoolCheck: false,
        name: '',
        englishName: '',
        birthday: '',
        email: '',
        phone: '',
        sex: '',
        armyStatus: 3,
        jobId: '',
        incomingRouteId: '',
        applyRouteId: 9,
      },
      eduMock: {
        type: null,
        name: '',
        startDate: '',
        endDate: ''
      },
      educations: [],
      certiMock: {
        type: null,
        lang: '',
        exam: '',
        grade: '',
        score: '',
        date: '',
        organization: ''
      },
      certifications: [],
      skill: '',
      academy: '',
      award: '',
      careerMock: {
        company: '',
        department: '',
        startDate: '',
        endDate: '',
        position: '',
        job: '',
        reason: '',
        isNowChecked: false,
        desc: '',
      },
      careers: [],
      projectMock: {
        title: '',
        startDate: '',
        endDate: '',
        // projectType: '',
        company: '',
        desc: '',
        // tool: ''
      },
      projects: [],
      // introduction: '',
      // goal: '',
      uploadList: [],
      careerUpload: [],
      urlList: [''],
      patriotYN: '',
      patriot: '',
      handicap: '',
      handicapList: [
        this.$t('recruit.form.handicap_none'),
        this.$t('recruit.form.handicap_normal'),
        this.$t('recruit.form.handicap_work'),
        this.$t('recruit.form.handicap_patriot'),
      ],
      applicantRules: {
        jobId: [{required: true, trigger: 'blur'}],
        name: [{required: true, trigger: 'blur'}],
        englishName: [{required: true, trigger: 'blur'}],
        birthday: [{required: true, trigger: 'blur'}],
        email: [{required: true, trigger: 'blur'}],
        phone: [{required: true, trigger: 'blur'}],
      },
      applyResult: {},
      showFinalCheck: false,
      isFreelancer: false,
      clickedApplyBtn: false,
      isSkillVisible: false,
      isCertiVisible: false,
      isAcademyVisible: false,
      isAwardVisible: false,
      isProjectVisible: false,
    };
  },
  computed: {
    companies() {
      return this.careers.map(c => c.company);
    },
    timeStamp(){
      return new Date().getTime();
    },
    loadingGif() {
      return require('@/assets/recruit/recruit_loading.gif');
    },
  },
  watch: {
    patriotYN() {
      this.patriot = this.patriotYN === 'patriotY' ? true : false;
    },
    selectedValue() {
      if(typeof this.selectedValue[0] === 'number'){
        this.$store.dispatch('app/setRecruitType',this.selectedValue[0]);
        this.$store.dispatch('user/setJobId',this.selectedValue[1]);
      }
    },
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('applicantForm'))!==null) {
      this.setLocalStorage();
    }
  },
  async created() {
    await this.getJobList();
    await this.getRoute();
    if (this.$store.getters.jobId) {
      this.applicantForm.jobId = parseInt(this.$store.getters.jobId, 10);
      const job = this.appliableList.find(j => j.id === this.applicantForm.jobId);
      this.selectedValue = [job.jobTypeId, job.id];
    }
    this.applicationId = this.$store.state.user.applicationId || window.sessionStorage.getItem('applicationId');
    window.sessionStorage.setItem('applicationId', this.applicationId);
    this.educations.push(Object.assign({}, this.eduMock));
    this.careers.push(Object.assign({}, this.careerMock));
    this.projects.push(Object.assign({}, this.projectMock));
    this.certifications.push(Object.assign({}, this.certiMock));

    const { name, birthday, email, phone, recruitPoolCheck } = this.$store.getters.userForm;
    this.applicantForm.name = name;
    this.applicantForm.birthday = birthday;
    this.applicantForm.email = email;
    this.applicantForm.phone = phone;
    this.applicantForm.recruitPoolCheck = recruitPoolCheck;

  },
  methods: {
    autoCompleteBirthday: function (key) {
      const birthday = this.applicantForm.birthday;
      const year = birthday.substr(0,4);
      const firstDot = birthday.substr(4,1);
      const month = birthday.substr(5,2);
      const secondDot = birthday.substr(7,1);
      if( key.keyCode == 8 || key.keyCode == 46 || key.key === '.' ) return false;
      if(birthday.length > 3 && firstDot !== '.'){
        this.applicantForm.birthday = year + '.';
      }else if(birthday.length > 6 && secondDot !== '.'){
        this.applicantForm.birthday = year + '.' + month + '.';
      }
    },
    async getJobList() {
      const query = {};
      const result = await getJobs(query);
      const list = [];
      const cascaderList = [{
        value: 1,
        label: '게임기획/시나리오',
        children: []
      }, {
        value: 2,
        label: '데이터 분석',
        children: []
      }, {
        value: 3,
        label: '마케팅',
        children: []
      }, {
        value: 4,
        label: '아트',
        children: []
      }, {
        value: 6,
        label: '프로그래밍',
        children: []
      }, {
        value: 7,
        label: '전문연구요원',
        children: []
      },{
        value: 12,
        label: '인사/조직문화',
        children: []
      }, {
        value: 13,
        label: '영상/미디어',
        children: []
      }, {
        value: 14,
        label: '프리랜서',
        children: []
      }, {
        value: 15,
        label: '관계사',
        children: []
      }, {
        value: 16,
        label: '인턴',
        children: []
      }];

      result.data.items.forEach(type => {
        type.job.forEach(j => {
          const jobObj = {
            type: type.name,
            type_en: type.name_en,
            ...j
          };

          list.push(jobObj);

          const parent = cascaderList.find(c => c.value === j.jobTypeId);
          if (parent) {
            parent.children.push({
              value: j.id,
              label: j.alias || j.name
            });
          }
        });
      });
      this.appliableList = list;
      this.cascaderList = cascaderList;
    },
    async getRoute() {
      const result = await getRoute({ checkIncoming: true });
      this.routeList = result.data.items;
    },
    setLocalStorage() {
      this.applicantForm = JSON.parse(localStorage.getItem('applicantForm')) || {};
      this.educations = JSON.parse(localStorage.getItem('educations')) || [{ ...this.eduMock }];
      this.certifications = JSON.parse(localStorage.getItem('certifications')) || '';
      this.careers = JSON.parse(localStorage.getItem('careers')) || [];
      this.projects = JSON.parse(localStorage.getItem('projects')) || [];
      // this.introduction = JSON.parse(localStorage.getItem('introduction')) || '';
      this.skill = JSON.parse(localStorage.getItem('skill')) || '';
      this.academy = JSON.parse(localStorage.getItem('academy')) || '';
      this.award = JSON.parse(localStorage.getItem('award')) || '';
      this.urlList = JSON.parse(localStorage.getItem('urlList')) || [''];
    },
    CheckFinally() {
      if(!this.isFreelancer){
        if(!this.validatePatriot()) {
          this.showFinalCheck = false;
          this.$message({
            message: '보훈여부를 체크해주세요.',
            type: 'error'
          });
          return false;
        }else if(!this.validateHandicap()) {
          this.showFinalCheck = false;
          this.$message({
            message: '장애사항을 선택해주세요.',
            type: 'error'
          });
          return false;
        }else if(!this.validateEduForm()){
          this.showFinalCheck = false;
          this.$message({
            message: '학력사항의 모든 항목들을 채워주세요.',
            type: 'error'
          });
          return false;
        }
        // else if(!this.validateGoal()){
        //   this.showFinalCheck = false;
        //   this.$message({
        //     message: '직무 질문의 모든 항목들을 채워주세요.',
        //     type: 'error'
        //   });
        //   return false;
        // }else if(!this.validateIntroduction()){
        //   this.showFinalCheck = false;
        //   this.$message({
        //     message: '직무 질문의 모든 항목들을 채워주세요.',
        //     type: 'error'
        //   });
        //   return false;
        // }
      }
      if(!this.validateEmail()) {
        this.showFinalCheck = false;
        return false;
      }else if(!this.validateApplicantForm()) {
        this.showFinalCheck = false;
        this.$message({
          message: '지원 정보 및 개인정보의 모든 항목들을 채워주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.isFresh){
        if(!this.careers.length>0 || !this.careers[0].company){
          this.showFinalCheck = false;
          this.$message({
            message: '경력 지원자는 경력사항을 입력해주세요.',
            type: 'error'
          });
          return false;
        }
      }
      this.showFinalCheck = true;
    },
    goBackStep() {
      this.activeTab -= 1;
    },
    addEdu() {
      this.educations.push(Object.assign({}, this.eduMock));
    },
    addCerti() {
      this.certifications.push(Object.assign({}, this.certiMock));
    },
    removeEdu(idx) {
      this.educations.splice(idx, 1);
    },
    removeCerti(idx) {
      this.certifications.splice(idx, 1);
    },
    addCareer() {
      this.careers.push(Object.assign({}, this.careerMock));
    },
    removeCareer(idx) {
      this.careers.splice(idx, 1);
    },
    addProject() {
      this.projects.push(Object.assign({}, this.projectMock));
    },
    removeProject(idx) {
      this.projects.splice(idx, 1);
    },
    validateEmail() {
      let isEmailValid = false;
      const email = this.applicantForm.email;
      const regex = /\S+@\S+\.\S+/;
      if(!regex.test(email)){
        this.$message({
          message: '이메일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isEmailValid = true;
      }
      return isEmailValid;
    },
    validateApplicantForm() {
      let isValid = false;
      this.$refs['applicantForm'].validate((valid) => {
        isValid = valid;
      });
      return isValid;
    },
    validateEduForm() {
      return this.educations.every(e => {
        return e.type !== '' && e.name && e.startDate;
      });
    },
    validatePatriot() {
      return this.patriot !== '' ? true : false;
    },
    validateHandicap() {
      return this.handicap !== '' ? true : false;
    },
    // validateGoal() {
    //   return this.goal;
    // },
    // validateIntroduction() {
    //   return this.introduction;
    // },
    async handleFileRemoveChange(file, fileList) {
      if (file.id) {
        await removeFile({ applicationId: this.applicationId, id: file.id });
      }

      this.uploadList = fileList;
    },
    async handleCareerFileRemoveChange(file, fileList) {
      if (file.id) {
        await removeFile({ applicationId: this.applicationId, type: 'career', id: file.id });
      }
      this.careerUpload = fileList;
    },
    handleUploadChange(file, fileList) {
      this.uploadList = fileList;
    },
    handleCareerChange(file, fileList) {
      this.careerUpload = fileList;
    },
    openTempSaveModal() {
      this.$refs.temporarySaveModal.style.display = 'block';
    },
    closeTempSaveModal() {
      this.$refs.temporarySaveModal.style.display = 'none';
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },
    async uploadFiles(data) {
      let fileList = [];

      if (data.length) {
        const sParam = data.map(file => {
          return {
            name: file.name,
            type: file.raw.type
          };
        });

        const signedResult = await getUploadUrl({ items: sParam });
        const uploadUrlList = signedResult.data.uploadUrlList;
        const downloadUrlList = signedResult.data.downloadUrlList;

        const promiseList = uploadUrlList.map((url, idx) => {
          return new Promise((resolve) => {
            axios.put(url, data[idx].raw, {
              headers: {
                'Content-Type': data[idx].raw.type
              }
            }).then(() => {
              resolve(true);
            }).catch(() => {
              resolve(false);
            });
          });
        });

        const result = await Promise.all(promiseList);

        if (result.indexOf(false) > -1) {
          this.$alert(this.$t('recruit.apply_upload_fail'), this.$t('recruit.apply_fail_title'), { dangerouslyUseHTMLString: true });
          this.dialogLoading = false;

          return false;
        }

        downloadUrlList.forEach((url, idx) => {
          sParam[idx].url = url;
        });

        fileList = downloadUrlList.map((url, idx) => {
          return {
            ...sParam[idx],
            url
          };
        });
      }

      return fileList;
    },
    async apply() {
      this.clickedApplyBtn = true;
      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }

      const referrer = Cookies.get('referrer') || '';

      let applyResult;

      this.isTemp = false;

      const filteredUploadList = this.uploadList.filter(item => item.status!=='success');
      const filteredCareerUploadList = this.careerUpload.filter(item => item.status!=='success');
      const fileList = await this.uploadFiles(filteredUploadList);
      const careerFile = await this.uploadFiles(filteredCareerUploadList);
      const urlListObj = this.urlList.map(item => item = { url: item });

      applyResult = await reRegister({
        isTemp: this.isTemp,
        isFresh: this.isFresh,
        applicantId: this.applicationId,
        applicantForm: this.applicantForm,
        patriot: this.patriot,
        handicap: this.handicap,
        educations: this.educations,
        certifications: this.certifications,
        skill: this.skill,
        academy: this.academy,
        award: this.award,
        projects: this.projects,
        // introduction: this.introduction,
        // goal: this.goal,
        urlList: urlListObj,
        careers: this.careers,
        referrer,
        careerFile,
        fileList,
      });

      if (applyResult.data.success) {
        this.applyLoading = true;
        this.showFinalCheck = false;
        setTimeout(() => {
          this.$router.push('/recruit/apply/complete');
        }, 2500);
      } else {
        this.$alert(this.$t('recruit.apply_fail'), this.$t('recruit.apply_fail_title'), { dangerouslyUseHTMLString: true });
      }

    },
    async saveTemp(applicantId) {
      if(this.applicantForm.jobId==='') {
        this.closeTempSaveModal();
        this.$message({
          message: '지원정보를 입력해주세요.',
          type: 'error'
        });
        return false;
      } else if(this.applicantForm.name===''){
        this.closeTempSaveModal();
        this.$message({
          message: '이름을 입력해주세요.',
          type: 'error'
        });
        return false;
      }

      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }

      const filteredUploadList = this.uploadList.filter(item => item.status!=='success');
      const filteredCareerUploadList = this.careerUpload.filter(item => item.status!=='success');

      const fileList = await this.uploadFiles(filteredUploadList);
      const careerFile = await this.uploadFiles(filteredCareerUploadList);
      const referrer = Cookies.get('referrer') || '';
      const urlListObj = this.urlList.map(item => item = { url: item });

      this.isTemp = true;

      const tempSaveResult = await saveTemp({
        isTemp: this.isTemp,
        isFresh: this.isFresh,
        applicantId: applicantId,
        applicantForm: this.applicantForm,
        patriot: this.patriot,
        handicap: this.handicap,
        educations: this.educations,
        certifications: this.certifications,
        skill: this.skill,
        academy: this.academy,
        award: this.award,
        projects: this.projects,
        // introduction: this.introduction,
        // goal: this.goal,
        urlList: urlListObj,
        careers: this.careers,
        referrer,
        careerFile,
        fileList,
      });

      if (tempSaveResult.data.success) {
        this.applicationId = tempSaveResult.data.stepResult[0].applicantId;
        if(this.$route.path === '/recruit/edit-apply'){
          window.history.replaceState(null, '', '/recruit/edit-apply/temp-saved');
        }else{
          window.history.replaceState(null, '', '/recruit/apply/temp-saved');
        }
        this.applyLoading = true;
        this.closeTempSaveModal();
        setTimeout(() => {
          this.applyLoading = false;
        }, 2500);
      } else {
        this.$message({
          message: '임시저장을 완료할 수 없습니다. 에러가 계속되면 쿡앱스 인사팀에 문의해주세요.',
          type: 'error'
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/apply.scss";
.edu__list:not(:last-of-type){
  &::after{
    content: '';
    display: block;
    height: 1px;
    background-color: #e1e1e1;
    margin-left: -20px;
    width: calc(100% + 40px);
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
</style>
<style lang="scss">
.mobile .apply__new {
  .el-upload {
    display: block;
    .el-upload-dragger {
      width: 100%;
    }
  }
  .el-select {
    width: 100% !important;
  }
  .urlList {
    display: block;
    .el-input {
      margin-top: 1rem;
      width: calc(100% - 40px) !important;
    }
  }
}
.isFresh__checkbox {
  position: absolute;
  top: 30px;
  right: 0;
  .el-checkbox__label {
    font-size: 16px;
    position: relative;
    top: 2px;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  .el-checkbox__inner::after {
    width: 5px;
    height: 9px;
    left: 5px;
  }
}
.applyNew__preview {
  .el-dialog__close {
    font-size: 24px;
  }
  .mobile & .el-dialog {
    width: 100% !important;
    margin-top: 0 !important;
  }
}
.form__item--block {
  display: block !important;
  .el-form-item__content {
    display: block !important;
  }
}
.education--form {
  .el-form-item {
    margin-bottom: 0 !important;
  }
  .el-form-item__error {
    padding-top: 0px !important;
  }
}
</style>
