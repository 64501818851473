<template>
  <div
    class="app-container apply__new"
    style="padding: 100px 0px 120px"
  >
    <h1 class="apply__tit font-bold text-3xl mt-5 mb-5">
      <span class="highlight">
        <span>📝 지원서 작성하기</span>
      </span>
    </h1>
    <SimpleFormEN v-if="applyRecruitType === 14 && applyJobId === 250" />
    <SimpleFormKR v-if="applyRecruitType === 14 && applyJobId !== 250" />
    <DefaultForm v-if="applyRecruitType !== 14 && !$store.getters.editSimplyApply" />
    <AfterSimpleApplyForm v-if="applyRecruitType !== 14 && $store.getters.editSimplyApply" />
  </div>
</template>
<script>
import DefaultForm from './components/defaultForm.vue';
import SimpleFormEN from './components/simpleFormEN.vue';
import SimpleFormKR from './components/simpleFormKR.vue';
import AfterSimpleApplyForm from './components/afterSimpleApplyForm.vue';

export default {
  name: 'Apply',
  components: {
    DefaultForm,
    SimpleFormEN,
    SimpleFormKR,
    AfterSimpleApplyForm,
  },
  data() {
    return {
      applyRecruitType: 0,
      applyJobId: 0,
    };
  },
  computed: {
    recruitType() {
      return this.$store.getters.recruitType;
    },
    jobId() {
      return this.$store.getters.jobId;
    }
  },
  watch: {
    recruitType() {
      this.applyRecruitType = this.recruitType;
    },
    jobId() {
      this.applyJobId = this.jobId;
    }
  },
  created(){
    window.fbq('track', 'cookapps_apply_view');
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/apply.scss";
</style>

